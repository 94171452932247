import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Grid, TextField, FormControl, CircularProgress, CardHeader, Avatar } from "@mui/material";
import "./Create.scss";
import _ from "lodash";
import DesktopNavTop from '../components/Desktop.Nav.Top';
import utils from "../utils";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Create = () => {
    // Setup our redux dispatching and router 
    const navigate = useNavigate();

    // Set up our state management 
    const [isLoading, setLoadingStatus] = useState(false);
    const [qrLabel, setQRLabel] = useState('');
    const [qrDescription, setQRDescription] = useState('');
    const [qrLink, setQRLink] = useState('');

    const submitRequest = () => {
        utils.apiCall('post', '/qr_codes/submit', { qr_label: qrLabel, qr_description: qrDescription, qr_link: qrLink }).then(response => {
            if (response.data.payload) {
                navigate('/qr_codes');
            } else {
                alert('Oops! Looks like that didnt work...');
            }
        }).catch(e => {
            alert(e);
        });
    };

    const isURLValid = () => {
        if (qrLink.length == 0) return false;

    }

    const getWelcomeContent = () => {
        return (
            <>
                <Card variant="outlined">
                    <CardHeader
                        avatar={<Avatar><FontAwesomeIcon icon={faLink} /></Avatar>}
                        sx={{ backgroundColor: 'rgb(124, 190, 223)' }}
                        title="Create a new QR Code"
                        titleTypographyProps={{
                            variant: 'h6'
                        }}
                        subheader="Fill out the details below and a trackable QR Code will be generated."
                        subheaderTypographyProps={{
                            variant: 'p'
                        }}>
                    </CardHeader>
                    <CardContent>
                        <div className="qr-code-input-container">
                            <div className={"qr-code-input-item " + (qrLabel.length > 0 ? 'ok' : 'invalid')}>
                                <Typography variant="p" component="p">
                                    <b>Label</b>
                                    <br />
                                    <i>What do you want to call this shortcut? It could be an employee name, a restaraunt menu, anything.</i>
                                </Typography>
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <TextField
                                        autofocus
                                        onChange={(e) => setQRLabel(e.target.value)}
                                        error={qrLabel.length < 4}
                                        helperText={'You must label your QR Code (minimum length 4 characters)'}
                                        id="label"
                                        type="text"
                                        label="Enter a name to track for your QR Code"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <div className={"qr-code-input-item " + (isURLValid() ? 'ok' : 'invalid')}>
                                <Typography variant="p" component="p">
                                    <b>URL</b>
                                    <br />
                                    <i>What URL should we redirect this QR Code to?</i>
                                </Typography>
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <TextField
                                        onChange={(e) => setQRLink(e.target.value)}
                                        error={() => isURLValid()}
                                        helperText={'You must describe your QR Code (minimum length 4 characters)'}
                                        id="label"
                                        type="text"
                                        label="https://www.fittedtech.com"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" style={{ width: '100%' }} onClick={submitRequest}>Generate</Button>
                    </CardActions>
                </Card>
            </>
        )
    };

    if (isLoading) {
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <>
                <DesktopNavTop page={"home"} />
                <Grid style={{ marginBottom: '75px', paddingLeft: '33%', paddingRight: '33%' }} container spacing={2} id="home_wrapper">
                    <Grid item xs={12} >
                        {getWelcomeContent()}
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Create
