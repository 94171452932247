import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { Grid, Input, TextField, FormControl, InputLabel, Select, MenuItem, ImageListItemBar, Box, Paper, BottomNavigationAction, BottomNavigation, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Home.scss";
import { loadPage, setQRCodeDescription } from "../redux/actions/pageActions";
import _ from "lodash";
import DesktopNavTop from '../components/Desktop.Nav.Top';
import utils from "../utils";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { faChartLine, faPlusCircle, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { loadQRCodes } from '../redux/actions/pageActions';
import './QRCodes.scss';

const QRCodes = () => {
    // Setup our redux dispatching and router
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const qrCodes = useSelector(state => state.data.qr_codes);

    // Set up our state management 
    const [isLoading, setLoadingStatus] = useState(true);
    const [qrLabel, setQRLabel] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('all');

    useEffect(() => {
        if (isLoading) {
            dispatch(loadQRCodes()).then(res => {
                setLoadingStatus(false);
            }).catch(e => {
                alert("An error occured while loading your QR Codes");
                console.log(e);
                setLoadingStatus(false);
            })
        }
    }, [isLoading]);

    const handleCategoryFilter = (category) => setCategoryFilter(category);


    /**
     * Resets the content of the component
     */
    const refreshContent = () => {
        setLoadingStatus(true);
        dispatch(loadQRCodes()).then(res => {
            setLoadingStatus(false);
        }).catch(e => {
            alert("An error occured while loading your QR Code activity");
            console.log(e);
            setLoadingStatus(false);
        });
    }

    /**
     * Delete a QR Code from your account
     * @param {object} qrCode 
     */
    const deleteQRCode = (qrCode) => {
        if (!window.confirm("Are you sure you want to delete this QR Code? This cannot be undone and all associated logs will be deleted.")) return;

        // Delete the visit log 
        utils.apiCall('delete', '/qr_codes/' + qrCode.uuid).then(response => {
            refreshContent();
        }).catch(e => {
            alert("An error occured while deleting your QR Code visit log");
            console.log(e);
            refreshContent();
        });
    };

    const descriptions = [
        'Pella',
        'Tacoma Showroom'
    ];

    const handleChange = (qrRecord, event) => {
        dispatch(setQRCodeDescription(qrRecord.qr_id, event.target.value))
    }

    const getContent = () => {
        if (qrCodes.length === 0) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant="contained" size="small" color="success" onClick={() => navigate('/create')}>
                        <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Create a new QR Code
                    </Button>
                </div>
            );
        }

        return (
            <List sx={{ width: '100%' }}>
                <ListItem alignItems="flex-start" className={"qrCodeBoxIntro"}>
                    <ListItemText>
                        <div style={{ width: '49%', display: 'inline' }}>
                            <h2>QR Code Dashboard</h2>
                            <p style={{ display: 'inline' }}>{qrCodes.length === 0 ? "We couldn't find any QR Codes for your account, use the button below to make one." : "Looks like you have already created some QR Codes. Nice work!"}</p>
                        </div>
                        <div style={{ width: '49%', display: 'inline' }}>
                            <hr />
                            <h4 style={{ margin: 0, padding: 0 }}>Filter Category</h4>
                            <select>
                                <option value={'all'} onClick={handleCategoryFilter.bind(this, 'all')}>All</option>
                                {descriptions.map(description => {
                                    return <option value={description} onClick={handleCategoryFilter.bind(this, description)}>{description}</option>
                                })}
                                <option value={'No Category'} onClick={handleCategoryFilter.bind(this, 'No Category')}>No Category</option>
                            </select>
                            <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={() => navigate('/create')}>
                                <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;Create A New QR Code
                            </Button>

                        </div>
                    </ListItemText>
                </ListItem >
                {
                    qrCodes.filter(qrCode => {
                        if (categoryFilter === 'all') {
                            return true;
                        } else if (categoryFilter === 'No Category' && qrCode.description === '') {
                            return true;
                        }
                        return categoryFilter === qrCode.description;
                    }).map((qrCode, idx) => {
                        return (
                            <>
                                <ListItem alignItems="flex-start" className={"qrCodeBox"}>
                                    <ListItemAvatar>
                                        <img src={qrCode.data_url} alt="img" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondary={
                                            <>
                                                <h2 style={{ margin: 5, padding: 0 }}>{qrCode.title}</h2>
                                                <div style={{ backgroundColor: 'rgba(0, 0, 0, .1)', margin: 5, padding: 10 }}>
                                                    <h4 style={{ margin: 0, padding: '0px 0px 0px 0px' }}>Category</h4>
                                                    <select style={{ width: 250 }} onChange={handleChange.bind(this, qrCode)}>
                                                        <option value="N/A">No Category</option>
                                                        {descriptions.map(category => <option value={category} selected={qrCode.description === category}>{category}</option>)}
                                                    </select>
                                                </div>
                                                <br />
                                                <div style={{ backgroundColor: 'rgba(0, 0, 0, .1)', margin: 5, padding: 10 }}>
                                                    <h4 style={{ margin: 0, padding: '5px 0px' }}>QR Code Visits</h4>
                                                    <hr />
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Total</th>
                                                                <th>Current Month</th>
                                                                <th>Last Month</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{qrCode.visits_total}</td>
                                                                <td>{qrCode.visits_current_month}</td>
                                                                <td>{qrCode.visits_last_month}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <pre>
                                                    <b>Actions</b>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <Button variant="contained" size="small" color="error" onClick={deleteQRCode.bind(this, qrCode)}>
                                                            &nbsp;<FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Delete QR Code
                                                        </Button>
                                                        &nbsp;
                                                        <Button variant="contained" size="small" color="info" onClick={(e) => { navigate('/logs/' + qrCode.uuid) }}>
                                                            &nbsp;<FontAwesomeIcon icon={faChartLine} />&nbsp;&nbsp;View Logs
                                                        </Button>
                                                    </div>
                                                </pre>
                                            </>
                                        }
                                    />
                                </ListItem>
                            </>
                        )
                    })
                }
            </List >
        )
    };

    return (
        <>
            <DesktopNavTop page={"home"} />
            <Grid style={{ marginBottom: '75px', paddingLeft: '33%', paddingRight: '33%' }} container spacing={2} id="home_wrapper">
                <Grid item xs={12} >
                    {isLoading ? <p style={{ color: 'white', textAlign: 'center', marginTop: '10%' }}>Loading...</p> : getContent()}
                </Grid>
            </Grid >
        </>
    )
}

export default QRCodes
