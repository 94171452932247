let initialState = {
    qr_codes: [],
    logs: {}
};

export const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_QR_CODES':
            return {
                ...state,
                qr_codes: action.payload
            }
        case 'GET_QR_DATA':
            return {
                ...state,
                logs: action.payload
            }
        default:
            return state
    }
}

