import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from "react-router-dom";
import { Grid, Input, ButtonGroup, FormControl, InputAdornment, ImageList, ListItem, ListItemAvatar, ListItemText, ImageListItemBar, Box, Paper, BottomNavigationAction, BottomNavigation, CircularProgress, CardHeader, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Home.scss";
import { loadPage } from "../redux/actions/pageActions";
import _ from "lodash";
import DesktopNavTop from '../components/Desktop.Nav.Top';
import utils from "../utils";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { loadQRCodeData, deleteVisitLog } from '../redux/actions/pageActions';
import moment from "moment";
import { faArrowCircleLeft, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';

const Logs = () => {
    // Setup our redux dispatching and router
    const { qrCodeID } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const qrCodes = useSelector(state => state.data.qr_codes);
    const qrVisits = useSelector(state => state.data.logs.qr_visits);

    // Set up our state management 
    const [qrCode, setQRCode] = useState(qrCodes.filter(qrCode => qrCode.uuid === qrCodeID)[0]);
    const [isLoading, setLoadingStatus] = useState(true);

    useEffect(() => {
        if (isLoading) {
            dispatch(loadQRCodeData(qrCode)).then(res => {
                setLoadingStatus(false);
            }).catch(e => {
                alert("An error occured while loading your QR Code activity");
                console.log(e);
                setLoadingStatus(false);
            });
        }
    }, [qrCodes]);

    /**
     * Resets the content of the component
     */
    const refreshContent = () => {
        setLoadingStatus(true);
        dispatch(loadQRCodeData(qrCode)).then(res => {
            setLoadingStatus(false);
        }).catch(e => {
            alert("An error occured while loading your QR Code activity");
            console.log(e);
            setLoadingStatus(false);
        });
    }

    /**
     * Deletes a visit record 
     * @param {object} row - A QR Visit Object
     */
    const deleteVisit = (row) => {
        // Prompt to make sure
        if (!window.confirm("Are you sure you want to delete this visit event? There is no way to recover it once it is gone.")) return;

        // Delete the visit log 
        utils.apiCall('delete', '/qr_visits/' + row.uuid).then(response => {
            refreshContent();
        }).catch(e => {
            alert("An error occured while deleting your QR Code visit log");
            console.log(e);
            refreshContent();
        });
    };

    const getContent = () => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">IP Address</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">Success</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">Timestamp</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {qrVisits.length === 0 ? (
                            <TableRow key={0} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell align="center">N/A</TableCell>
                                <TableCell align="center">N/A</TableCell>
                                <TableCell align="center">N/A</TableCell>
                                <TableCell align="center">N/A</TableCell>
                            </TableRow>
                        ) : qrVisits.map((row) => (
                            <TableRow key={row.qr_visit_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell align="center">{row.ip_address}</TableCell>
                                <TableCell align="center">{row.success_ind === 1 ? 'OK' : 'Failure'}</TableCell>
                                <TableCell align="center">{moment(row.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell align="center"><Button variant="contained" color="error" onClick={deleteVisit.bind(this, row)}><FontAwesomeIcon icon={faTrash} /></Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    return (
        <>
            <DesktopNavTop page={"home"} />
            <Grid style={{ marginBottom: '75px', paddingLeft: '33%', paddingRight: '33%' }} container spacing={2} id="home_wrapper">
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader
                            action={
                                <ButtonGroup variant="contained" aria-label="action buttons">
                                    <Button color="primary" onClick={() => navigate('/qr_codes')} sx={{ marginRight: 1 }}>
                                        <FontAwesomeIcon icon={faArrowCircleLeft} />&nbsp;&nbsp;Go Back
                                    </Button>
                                    <Button color="success" onClick={refreshContent}>
                                        <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;Refresh
                                    </Button>
                                </ButtonGroup>
                            }
                            title={"QR Code Activity - " + qrCode.title}>
                        </CardHeader>
                    </Card>
                    <br />
                    {isLoading ? (
                        <Card variant="outlined" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardContent>
                                <CircularProgress />
                            </CardContent>
                        </Card>
                    ) : getContent()}
                </Grid>
            </Grid >
        </>
    );
}

export default Logs;
