import axios from 'axios';
import config from './../../config';
import utils from "./../../utils";

export const loadGlobalData = () => dispatch => {
    return utils.apiCall('post', '/qr_codes/all').then(response => {
        dispatch({
            type: 'GET_QR_CODES',
            payload: response.data.payload.qr_codes
        });

        return response.data.payload;
    }).catch(e => {
        alert(e);
    });
}

export const setQRCodeDescription = (qrCode, description) => dispatch => {
    return utils.apiCall('post', '/qr_codes/description', {
        qrCode,
        description
    }).then(() => {
        return utils.apiCall('post', '/qr_codes/all').then(response => {
            dispatch({
                type: 'GET_QR_CODES',
                payload: response.data.payload.qr_codes
            });
            return response.data.payload;
        }).catch(e => {
            alert(e);
        });
    }).catch(e => {
        alert(e);
    });
}

export const loadQRCodes = () => dispatch => {
    return utils.apiCall('post', '/qr_codes/all').then(response => {
        dispatch({
            type: 'GET_QR_CODES',
            payload: response.data.payload.qr_codes
        });
        return response.data.payload;
    }).catch(e => {
        alert(e);
    });
}

export const loadQRCodeData = (qrCode) => dispatch => {
    return utils.apiCall('post', '/qr_visits/' + qrCode.uuid).then(response => {
        dispatch({
            type: 'GET_QR_DATA',
            payload: {
                qr_code: qrCode,
                qr_visits: response.data.payload.qr_visits
            }
        });

        return response.data.payload;
    }).catch(e => {
        alert(e);
    });
}


