import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import "./Home.scss";
import _ from "lodash";
import DesktopNavTop from '../components/Desktop.Nav.Top';


const Home = () => {
    // Setup our redux dispatching and router
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const qrCodes = useSelector(state => state.data.qr_codes);

    // Set up our state management 
    const [isLoading, setLoadingStatus] = useState(false);
    const [qrLabel, setQRLabel] = useState('');
    const [qrDescription, setQRDescription] = useState('');

    // When we get our qrCodes, check if we should show welcome content
    useEffect(() => {
        if (qrCodes.length === 0) {
            navigate('/create');
        }
    }, [qrCodes]);

    const determineContent = () => {
        if (qrCodes.length === 0) {
            return <></>;
        } else {
            return (
                <>
                    {getHomeContent()}
                </>
            );
        }
    }

    const getHomeContent = () => {
        return (
            <>
                <h4>Howdy!</h4>
                <p>Looks like you know what you are doing.</p>
                <hr />
                <pre>
                    {JSON.stringify(qrCodes, null, 2)}
                </pre>
            </>
        )
    };

    if (isLoading) {
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <>
                <DesktopNavTop page={"home"} />
                <Grid style={{ marginBottom: '75px', paddingLeft: '33%', paddingRight: '33%' }} container spacing={2} id="home_wrapper">
                    <Grid item xs={12} >
                        {determineContent()}
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Home
