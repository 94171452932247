import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from "./routes/Home";
import './Router.scss';

import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";

import Protected from './routes/Protected';
import EditProfile from "./routes/Edit.Profile";
import Register from "./routes/Register";
import Login from "./routes/Login";
import QRCodes from "./routes/QRCodes";
import Logs from "./routes/Logs";
import Create from "./routes/Create";
import None from "./routes/None";
import store from './redux/store';

function Router() {
    const themeLight = createTheme(lightTheme);
    const themeDark = createTheme(darkTheme);

    const breakpoints = {
        xs: useMediaQuery('(min-width:0px)'),
        sm: useMediaQuery('(min-width:600px)'),
        md: useMediaQuery('(min-width:900px)'),
        lg: useMediaQuery('(min-width:1200px)'),
        xl: useMediaQuery('(min-width:1536)')
    };

    document.querySelector('#root').classList.add(breakpoints.sm ? 'root_desktop' : 'root_mobile');


    return (
        <ThemeProvider theme={themeLight}>
            <CssBaseline />
            <Provider store={store}>
                <BrowserRouter >
                    <Routes>
                        {/* =========== PUBLIC ROUTES ================ */}
                        <Route index={true} path='/' element={<Protected component={Home} />} />
                        <Route path='/profile/edit' element={<Protected component={EditProfile} />} />
                        {/* =========== PRIVATE ROUTES ================ */}
                        <Route path='/register' element={<Register />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/none' element={<None />} />
                        <Route path='/create' element={<Protected component={Create} />} />
                        <Route path='/qr_codes' element={<Protected component={QRCodes} />} />
                        <Route path='/logs/:qrCodeID' element={<Protected component={Logs} />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        </ThemeProvider >
    );
}

export default Router;
