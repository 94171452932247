import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

let store;
const initialState = {};
const middleware = [thunk];
const params = ((window.navigator.userAgent.includes('Chrome') || window.navigator.userAgent.includes('Firefox')) && window.__REDUX_DEVTOOLS_EXTENSION__ ? [applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })] : [applyMiddleware(...middleware)]);

store = createStore(
  rootReducer,
  initialState,
  compose(
      ...params
  )
);

export default store;