import utils from "./../../utils";

export const loginUser = (userCreds) => dispatch => {
    return utils.apiCall('post', '/auth/login', userCreds).then(response => {
        try {
            sessionStorage.setItem('jg_token', response.data.token);
            response.data.payload.user.user_id = Number(response.data.payload.user.user_id);
            dispatch({
                type: 'AUTH_USER',
                payload: response.data.payload.user
            });
        } catch (e) {
            console.log(e);
        }
        return response;
    });
}

export const fetchUserInfo = () => dispatch => {
    return utils.apiCall('post', '/user/get/me').then(response => {
        dispatch({
            type: 'AUTH_USER',
            payload: response.data.payload.user
        });
        return response;
    });
} 