import { Grid, Box } from "@mui/material";
import "./Login.scss";
import _ from "lodash";
import Logo from "./../assets/img/logo.png";

const None = () => {
    return (
        <Grid container id="root_login">
            <Grid item xs={0} lg={4}></Grid>
            <Grid item xs={12} lg={4}>
                <Box className="login_wrapper">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={Logo} style={{ height: '100px' }} alt="logo" />
                    </Box>
                    <p>It looks like someone tried to redirect you to a link that does not work :(, for help contact support@fittedtech.com</p>
                </Box>
            </Grid>
            <Grid item xs={0} lg={4}></Grid>
        </Grid >
    )
}

export default None;
