import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { Grid, Box, TextField, Button, FormControl, InputLabel, InputAdornment, CircularProgress } from "@mui/material";
import { FaUserCircle, FaKey, FaSpinner, FaQuestionCircle, FaInfoCircle, FaAt, FaEnvelope, FaCheck, FaTimes, FaArrowLeft, FaPhone } from "react-icons/fa";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from "moment";
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import "./Register.scss";
import { registerUser } from "./../redux/actions/userActions";
import _ from "lodash";
import utils from "./../utils";

const Register = () => {
    // Setup our redux dispatching and router
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Set up our state management 
    const [password, setPassword] = useState(false);
    const [verifyPassword, setVerifyPassword] = useState(false);
    const [email, setEmail] = useState(false);
    const [displayName, setDisplayName] = useState(false);
    const [firstName, setFName] = useState(false);
    const [lastName, setLName] = useState(false);
    const [phone, setPhone] = useState(false);
    const [dob, setDOB] = useState(null);
    const [isLoading, setLoadingStatus] = useState(false);

    const handleLogin = (event) => {
        setLoadingStatus(true);
    };


    const handleChange = (event) => {
        if (event.target.id === 'email') {
            setEmail(event.target.value);
        } else if (event.target.id === 'displayName') {
            setDisplayName(event.target.value);
        } else if (event.target.id === 'firstName') {
            setFName(event.target.value);
        } else if (event.target.id === 'lastName') {
            setLName(event.target.value);
        } else if (event.target.id === 'phone') {
            setPhone(event.target.value);
        } else if (event.target.id === 'dob') {
            setDOB(event.target.value);
        } else if (event.target.id === 'verifyPassword') {
            setVerifyPassword(event.target.value);
        } else {
            setPassword(event.target.value);
        }
    };

    const handleDOBChange = (event) => {
        if (event) {
            setDOB(event.format('MM/DD/YYYY'));
        }
    };

    const getRegisterBtnColor = () => {
        if (email && email.length > 6 && password && password.length > 6 && firstName && firstName.length > 1 && lastName && lastName.length > 1 && dob && dob.length > 1) {
            return "success";
        }

        return "primary";
    }


    const getPasswordVerificationStatus = () => {
        if (password === false && verifyPassword === false) {
            return {
                icon: <FaKey />,
                color: '',
                error: false,
                helperText: ''
            }
        } else if (password === verifyPassword) {
            return {
                icon: <FaCheck />,
                color: 'success',
                error: false,
                helperText: ''
            }
        } else {
            return {
                icon: <FaTimes />,
                color: 'error',
                error: true,
                helperText: 'The passwords do not match'
            }
        }
    }

    const handleCreateAccount = () => {
        utils.apiCall('post', '/auth/register', {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            dob: dob
        }).then(res => {
            if (res.data && res.data.msg == 'User created') {
                sessionStorage.setItem('jg_token', res.data.payload.token);
                navigate('/');
            } else {
                alert("An error occured");
            }
        }).catch(e => {
            if (e.response.status === 409) {
                alert('An account with that email already exists');
            } else {
                alert("An unknown error occured");
            }
            console.log(e);
        })
    }

    return (
        <Grid container id="root_register">
            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={12} md={4}>
                <Box className="register_wrapper">
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h2 style={{ textAlign: 'center' }}>Register an account for JumpGaze</h2>
                    </Box>
                    <form>
                        <h4>Login Information</h4>
                        <hr />
                        <FormControl variant="standard" fullWidth>
                            <TextField
                                id="email"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FaAt />
                                        </InputAdornment>
                                    ),
                                }}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                label="Email"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <TextField
                                id="password"
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FaKey />
                                        </InputAdornment>
                                    ),
                                }}
                                color={getPasswordVerificationStatus().color}
                                helperText={getPasswordVerificationStatus().helperText}
                                label="Password"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <TextField
                                id="verifyPassword"
                                type="password"
                                color={getPasswordVerificationStatus().color}
                                error={getPasswordVerificationStatus().error}
                                helperText={getPasswordVerificationStatus().helperText}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {getPasswordVerificationStatus().icon}
                                        </InputAdornment>
                                    ),
                                }}

                                label="Verify Password"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <h4>Personal Information</h4>
                        <hr />
                        <FormControl variant="standard" fullWidth margin="normal">
                            <TextField
                                id="firstName"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FaUserCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                label="First Name"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <TextField
                                id="lastName"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FaKey />
                                        </InputAdornment>
                                    ),
                                }}

                                label="Last Name"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <TextField
                                id="phone"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FaPhone />
                                        </InputAdornment>
                                    ),
                                }}
                                type="tel"
                                label="Phone Number"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    label="Birthday"
                                    value={dob}
                                    onChange={handleDOBChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl variant="standard" fullWidth margin="normal">
                            <Button margin="normal" fullWidth variant="contained" type="button" color={getRegisterBtnColor()} className="form__custom-button" disabled={getRegisterBtnColor() === 'primary'} onClick={handleCreateAccount}>
                                {isLoading ? (<FaSpinner className="spinner" />) : "Register"}
                            </Button>
                        </FormControl>
                        <hr />
                        <p style={{ textAlign: 'center' }}><small><a href='#' className="register_link" onClick={() => navigate('/login')}><FaArrowLeft /> Go back to login</a></small></p>
                    </form>
                </Box>
            </Grid >
            <Grid item xs={0} md={4}></Grid>
        </Grid >
    )
}

export default Register;
