import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { Grid, Button, FormControl, TextField, InputAdornment, Box, Avatar, ButtonGroup, Alert } from "@mui/material";
import { fetchUserInfo } from "../redux/actions/userActions";
import { FaCheck, FaDollarSign, FaSpinner, FaUserCircle } from "react-icons/fa";
import DesktopNavTop from '../components/Desktop.Nav.Top';
import utils from "../utils";
import _ from "lodash";
import "./Edit.Profile.scss";


const EditProfile = () => {
    // Setup our redux dispatching and router
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let user = useSelector((state) => {
        return state.user;
    });

    // Setup some flags
    const isCeleb = user.user_type == 2;

    // Set up our state management  
    const [errorText, setErrorText] = useState(false);
    const [avatarIsUploading, setAvatarUploading] = useState(false);
    const [avatarImage, setAvatarImage] = useState(user.thumbnail);
    const [shoutoutFee, setShoutoutFee] = useState(user.shoutout_fee);
    const [videoFee, setVideoFee] = useState(user.video_fee);
    const [bio, setBio] = useState(user.bio);
    const [displayName, setDisplayName] = useState(user.display_name);
    const [displayNameTaken, setDisplayNameTaken] = useState(false);
    const [settingsSaving, setSettingsSaving] = useState(false);
    const [settingsSaved, setSettingsSaved] = useState(false);

    const handleNameUpdate = async (e) => {
        setDisplayName(e.target.value.trim());

        utils.apiCall('post', '/user/display_name/check', {
            display_name: e.target.value.trim()
        }).then(response => {
            setDisplayNameTaken(response.data.payload.exists);
        });
    };
    const handleBioUpdate = async (e) => {
        setSettingsSaved(false);
        setBio(e.target.value);
    };
    const handleShoutoutFeeChange = async (e) => {
        setSettingsSaved(false);
        setShoutoutFee(e.target.value);
    };
    const handleVideoFeeChange = async (e) => {
        setSettingsSaved(false);
        setVideoFee(e.target.value);
    };
    const handleSaveChanges = async (e) => {
        setSettingsSaved(false);
        setSettingsSaving(true);

        utils.apiCall('post', '/user/info/update', {
            display_name: displayName,
            bio,
            video_fee: videoFee,
            shoutout_fee: shoutoutFee
        }).then(res => {
            if (!res.data.ok) {
                setSettingsSaving(false);
                setErrorText(res.data.msg);
            } else {
                setSettingsSaving(false);
                setSettingsSaved(true);
                setErrorText(false);
                dispatch(fetchUserInfo());
                navigate('/profile');
            }
        });
    };

    const handleFileChange = async (e) => {
        setAvatarUploading(true);

        let formData = new FormData()
        formData.append('profilePicture', e.target.files[0])

        fetch(`${process.env.REACT_APP_API_URL}/user/profile_picture`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('jg_token')}`
            },
            body: formData,
        }).then(response => {
            return response.json();
        }).then(response => {
            if (!response.ok) {
                setErrorText(response.msg);
                setAvatarUploading(false);
            } else {
                setAvatarImage(response.payload.url);
                setAvatarUploading(false);
            }
        }).catch(error => {
            alert('An HTTP Request error occured: ' + error);
        });
    }
    // useEffect(() => {
    //     dispatch(Something({
    //         "Something": Something,
    //         "Something": Something
    //     }));
    // }, [Something]);

    return (
        <>
            <DesktopNavTop page={"profile"} />
            <Grid sx={{ height: '90vh' }} container spacing={2}>
                <Grid item xs={0} md={4}></Grid>
                <Grid item xs={12} md={4}>
                    {isCeleb && (<Alert severity="info">Wow, you're a celebrity.</Alert>)}
                    <Box className="form_wrapper">
                        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '5px' }} >
                            <Avatar alt={user.first_name + ' ' + user.last_name} src={avatarImage} sx={{ width: 125, height: 125 }} />
                            <Button size="small" variant="contained" color="info" component="label" style={{ marginTop: '5px' }}>
                                {avatarIsUploading ? (<FaSpinner className="spinner" />) : 'Edit'}
                                <input hidden type='file' name='file' onChange={handleFileChange} />
                            </Button>
                        </Grid>
                        <form onSubmit={() => true}>
                            {isCeleb && (<h4>Public Information</h4>)}
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    id="display_name"
                                    error={displayNameTaken ? true : displayName.trim() === '' ? true : false}
                                    helperText={displayNameTaken ? 'This display name is already taken' : displayName.trim() === '' ? 'You must specify a display name' : ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaUserCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={displayName}
                                    onChange={handleNameUpdate}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    label="Display Name"
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    id="email"
                                    disabled
                                    value={user.email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaUserCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    label="Email"
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    value={bio}
                                    onChange={handleBioUpdate}
                                    id="bio"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    label="Tell the world what you're about!"
                                    multiline
                                    minRows={4}
                                />
                            </FormControl>
                            {isCeleb && (
                                <>
                                    <h4>Private Information</h4>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard">
                                                <TextField
                                                    id="shoutoutFee"
                                                    value={shoutoutFee}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FaDollarSign />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Shoutout Fee"
                                                    onChange={handleShoutoutFeeChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard">
                                                <TextField
                                                    id="videoFee"
                                                    value={videoFee}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FaDollarSign />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Video Call Fee"
                                                    onChange={handleVideoFeeChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </form>
                        <br />
                        <Button size="large" fullWidth variant="contained" color={settingsSaved ? "success" : "primary"} onClick={handleSaveChanges} disabled={displayNameTaken || avatarIsUploading}>
                            {settingsSaving ? (<FaSpinner className="spinner" />) : settingsSaved ? (<FaCheck />) : 'Save Changes'}
                        </Button>
                        {errorText && (<Alert severity="error">{errorText}</Alert>)}
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={0} md={4}></Grid>
        </>
    )
}

export default EditProfile
