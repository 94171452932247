import axios from 'axios';
const utils = {};

utils.apiCall = (method, endpoint, data, options) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('jg_token')}`;
    const requestConfig = Object.assign({}, {
        headers: {}
    }, options);

    const safeAPIUrl = process.env.REACT_APP_API_URL.charAt(process.env.REACT_APP_API_URL.length - 1) === '/' ? process.env.REACT_APP_API_URL.substr(0, process.env.REACT_APP_API_URL.length - 1) : process.env.REACT_APP_API_URL;
    const safeEndpoint = endpoint.includes('/') ? endpoint : '/' + endpoint;

    if (method.toLowerCase().trim() === 'get') {
        return axios.get(`${safeAPIUrl}${safeEndpoint}`, data, requestConfig);
    } else if (method.toLowerCase().trim() === 'delete') {
        return axios.delete(`${safeAPIUrl}${safeEndpoint}`, data, requestConfig);
    } else {
        return axios.post(`${safeAPIUrl}${safeEndpoint}`, data, requestConfig);
    }
};

utils.vibrate = (duration) => {
    const canVibrate = window.navigator.vibrate
    if (canVibrate) window.navigator.vibrate(duration)
}

utils.logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace('https://jumpgaze.com/login');
}

export default utils;