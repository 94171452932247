import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams, useSearchParams } from "react-router-dom";
import { fetchUserInfo } from "../redux/actions/userActions";
import { loadGlobalData } from "../redux/actions/pageActions";
import { FaSpinner } from "react-icons/fa";
import store from "./../redux/store";
import utils from './../utils';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const storeState = store.getState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [search, setSearch] = useSearchParams();
    const [auth, setAuth] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);

    useEffect(() => {
        // send jwt to API to see if it's valid
        let token = sessionStorage.getItem("jg_token");

        // Check if it's on the URL
        if (!token && window.location.href.includes('?token=')) {
            token = window.location.href.split('?token=')[1];
            sessionStorage.setItem('jg_token', token);
            window.location = window.location.href.split("?")[0];
        }

        if (token) {
            if (storeState.user.user_id) {
                if (storeState.data.qr_codes.length === 0) {
                    dispatch(loadGlobalData()).then(res2 => {
                        setAuth(true);
                        setIsTokenValidated(true);
                        navigate('/create');
                    });
                } else {
                    setIsTokenValidated(true);
                    setAuth(true);
                    navigate('/create');
                }
            } else {
                dispatch(fetchUserInfo()).then(res => {
                    if (res.data.ok) {
                        dispatch(loadGlobalData()).then(res2 => {
                            setAuth(true);
                            setIsTokenValidated(true);
                            navigate('/create');
                        });
                    } else {
                        setIsTokenValidated(true);
                        setAuth(false);
                        navigate('/create');
                    }
                }).catch(e => {
                    sessionStorage.clear();
                    alert(e);
                    utils.logout();
                });
            }
        } else {
            setIsTokenValidated(true);
            setAuth(false);
        }
    }, []);


    if (!isTokenValidated) {
        return <p style={{ textAlign: 'center' }}><FaSpinner className="spinner" /> Loading...</p>;
    } else if (!auth) {
        navigate('/login');
        return <></>;
    } else {
        return <Component {...rest} />;
    }
}

export default ProtectedRoute;